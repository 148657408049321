body {
  /* max-width: 1200px; */
  margin: 0 auto;
  background-color: #FFFFFF;
  /* background-color: #0F203E; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* color list
primary
  R:15 G:32 B:62 #0F203E
  R:255 G:255 B:255 #FFFFFF

second
  R:79 G:91 B:109 #4F5B6D
  R:123 G:137 B:159 #7B899F
  R:178 G:186 B:199 #B2BAC7
  R:209 G:214 B:221 #D1D6DD
  R:224 G:227 B:233 #E0E3E9

accent(blue)
  R:28 G:122 B:172 #1C7AAC #2191CD #29B5FF
  R:87 G:124 B:136 #577C88

sub
  R:146 G:126 B:100 #927E64

*/
.Table{
  border-collapse:collapse;
  border-spacing:0;
  margin: 0 auto;
}

.Table-rowcolumn-head{
  padding: 16px;

  text-align: right;
  vertical-align: bottom;
  border-bottom: #0F203E 2px solid;
  border-right: #0F203E 2px dotted;
}

.Table-rowhead{
  padding: 16px;

  text-align: right;
  vertical-align: center;
  border-right: #0F203E 2px dotted;
}
.Table-rowhead-main{
  padding: 16px;

  text-align: right;
  vertical-align: center;
  border-right: #0F203E 2px dotted;

  /* text-decoration-line: underline;
  text-decoration-color: #29B5FF; */
  background-color: #29B5FF44;
}

.Table-thead{
  padding: 16px;

  text-align: center;
  font-weight: bold;
  vertical-align: bottom;
  border-bottom: #0F203E 2px solid;
}

.Table-tbody{
  padding: 16px;

  text-align: center;
  vertical-align: center;
}

.Table-tbody-main{
  padding: 16px;

  text-align: center;
  vertical-align: center;

  /* text-decoration-line: underline;
  text-decoration-color: #29B5FF; */
  background-color: #29B5FF44;
}

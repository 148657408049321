

.Result-title {
  color: white;
  background-color: #0F203E;
  /* max-width: 100vw; */
  padding: 24px 24px;
  margin: 0px 0px;

  font-size: 2em;
}

.Result-section {
  margin: 0px auto;
  max-width: 1200px;
}

.Result-dxscore-body{
  text-align: left;

  padding: 16px 32px;
}
.Result-dxscore-result {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 32px;
}
@media (max-width: 800px) {
  .Result-dxscore-result {
    display: block;
  }
}
.Result-dxscore-rader {
  text-align: center;
  width: 75%;
  margin: 0 auto;
}
.Result-dxscore-table {
  text-align: center;
  margin: 0 auto;
}


.Result-4x-explain{
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  align-items:center;

  padding: 0px 16px;
}
@media (max-width: 800px) {
  .Result-4x-explain {
    display: block;
  }
}
.Result-4x-explain-body{
  text-align: left;
  /* width: 66% */
  padding: 8px 28px;
  margin-bottom: 32px;
}
.Result-4x-explain-img{
  text-align: center;
}
.Result-4x-explain-img-tag{
  text-align: center;
  object-fit: cover;
  object-position: 50% 100%;
  aspect-ratio: 4/3;

  max-width: 100%;
  max-height: 100%;
}

.Result-4x-score {
  max-width: min(1200px,100vw);
  padding: 16px auto;
}
.Result-4x-score-contents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 16px 0px;

  font-weight: 600;
}
.Result-4x-score-contents-svg {
  margin: 0px auto;
}
.Result-4x-score-contents-left {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  text-align: right;
  margin: 32px 16px;
  font-size: calc(24px + 1.0vmin);
}
.Result-4x-score-contents-left-top {
  margin: 0px 0px auto auto;
}
.Result-4x-score-contents-left-bottom {
  margin: auto 0px 0px auto;
}
.Result-4x-score-contents-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  text-align: left;
  margin: 32px 16px;
  font-size: calc(24px + 1.0vmin);
}
.Result-4x-score-contents-right-top {
  margin: 0px auto auto 0px;
}
.Result-4x-score-contents-right-bottom {
  margin: auto auto 0px 0px;
}
.Result-4x-score-contents-brown {
  color: #927E64
}
.Result-4x-score-contents-cyan {
  color: #577C88
}
.Result-4x-score-contents-blue {
  color:#1C7AAC
}


.Result-to-offering-body{
  text-align: left;
  padding: 8px 28px;
  margin-bottom: 32px;
}

.Result-offering {
  margin-bottom: 48px;
}
.Result-offering-title {
  padding: 32px;
  margin: 64px 0px 0px 0px;

  color: white;
  background-color: #0F203E;
}

.Result-advice {
  margin-bottom: 16px;
}

.Result-button {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center; 
  margin-bottom: 32px;
  margin-top:0px;
}

.ProgressBar{
  text-align: right;
  margin-left: auto;
  margin-right: 0px;
}
.ProgressBar-label{
  padding: 16px;
  padding-right: 32px;
}
/* .ProgressBar-bar{
  padding: 16px;
  padding-right: 32px;
} */
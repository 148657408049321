
.Offering {
  list-style: none;
  margin-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
}
.Offering-header {
  padding: 32px;
  margin: 32px 0px;

  font-size: calc(12px + 1.5vmin);
  border-bottom: #0F203E 3px solid;
}
.Offering-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  
  align-items:center;
  padding: 0 16px;
}
@media (max-width: 800px) {
  .Offering-container {
    display: block;
  }
}

.OfferingContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: auto;

  height: 100%;
  width: 100%;
}
.OfferingContainer-title {
  padding: 16px;

  font-size: calc(8px + 1.5vmin);
  background-color: #4F5B6D;
  color: white;
  overflow-wrap: normal;
  grid-column: 1;
}
.OfferingContainer-body {
  padding: 16px 20px;
  padding-bottom: 0px;
  flex: auto;

  text-align: left;
  font-size: calc(8px + 1.2vmin);
  background-color: #E0E3E9;
  overflow-wrap: normal;
  grid-column: 1;
}
.OfferingContainer-message {
  /* padding: 0px; */
  font-weight: bold;
  padding-bottom: 16px;
}
.OfferingContainer-accordion {
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: center;
  align-items: center;
  margin: auto 0px;
  padding: 0px;

  /* font-weight: bold; */
  text-decoration:underline;
  /* color: #2191CD */
}
.OfferingContainer-arrow-right {
  border: solid;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.OfferingContainer-arrow-down {
  border: solid;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.OfferingContainer-button {
  text-align: center;
  margin: 0px auto 0px auto;
  background-color: #E0E3E9;
  width: 100%;
}
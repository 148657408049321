.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  margin: 0 auto;
  
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(8px + 1.5vmin);
  /* color: white; */
  color: #0F203E;
}


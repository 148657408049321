
.Button-link {
  display:inline-block;
  background-color: #1C7AAC;
  padding: 8px 24px;
  margin: 32px 32px;
  border-radius: 10px;
  border: 0;

  color: white;
  font-size: calc(8px + 1.5vmin);
  text-decoration:none;
  /* pointer-events: none; */
}
.Button-link:hover,
.Button-link:focus {
  background-color: #2191CD;
}
.Button-link:active {
  background-color: #2191CD;
  color: white;
  outline: 2px solid #1C7AAC;
  text-decoration:none;
}
.Button-link:disabled {
  background-color: #7B899F;
}

.Button-link-sub {
  background-color: white;
  color: #0F203E;
  outline: 1px solid #0F203E;
  text-decoration:none;
}
.Button-link-sub:hover,
.Button-link-sub:focus {
  background-color: #E0E3E9;
}
.Button-link-sub:active {
  background-color: #E0E3E9;
  color: #0F203E;
  outline: 2px solid #1C7AAC;
  text-decoration:none;
}
.Button-link-sub:disabled {
  background-color: #E0E3E9;
}

.Button-link-large{
  padding: 24px 60px;

}

.Button-link-Link {
  display: block;
  color:inherit;
  font-size: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
